<template>
  <div class="solana-page solana-pool">
    <div class="solana-pool-switch">
      <s-select
        :options="items"
        v-model="type"
        :outlined="false"
      />

      <span class="solana-pool-switch-separator solana-title">/</span>

      <s-select
        v-model="pool"
        :options="cards"
        value_field="name"
        custom_trigger
        custom_option
        style="min-width: 127px"
      >
        <template v-slot:trigger="{item}">
          <img :src="require(`@/assets/img/solana/cards/${item.image}`)" :alt="item.name">
          <span>{{ item.name }}</span>
        </template>

        <template v-slot:option="{option}">
          <img :src="require(`@/assets/img/solana/cards/${option.image}`)" :alt="option.name">
          <span>{{ option.name }}</span>
        </template>
      </s-select>

    </div>

    <v-row>
      <v-col>
        <go-back label="solana.back_to_pools" url="/solana"/>

        <pool-card/>
      </v-col>
      <v-col>
        <pool-items/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GoBack from '@/components/Solana/GoBack';
import PoolCard from '@/components/Solana/PoolCard';
import PoolItems from '@/components/Solana/PoolItems';
import SSelect from '@/components/Solana/SSelect';

export default {
  name: 'Pool',
  components: {
    SSelect, PoolItems, GoBack, PoolCard,
  },
  data() {
    return {
      items: ['Single', 'Pair'],
      type: 'Single',
      pool: {
        image: '2.png',
        name: 'SHT',
      },
      cards: [
        {
          image: '1.png',
          name: 'SHT/ETH',
        },
        {
          image: '2.png',
          name: 'SHT',
        },
        {
          image: '3.png',
          name: 'SHT',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
</style>
