<template>
  <div class="p-item">
    <div class="p-item-container">
      <div class="p-item-name">
        <div class="p-item-image d-flex align-center">
          <img :src="require(`@/assets/img/solana/cards/1.png`)">
        </div>
        <div>
          SHT
          <div class="p-item-subname">{{ $t('solana.stking') }}</div>
        </div>
      </div>
      <div class="p-item-code">0x123jkm</div>
      <div class="p-item-percent">
        240%
        <div class="p-item-apy">{{ $t('solana.apy') }}</div>
      </div>
      <div class="p-item-percent p-item-starts" v-if="pool_type === 'upcoming'">
        1d 3h 56m
        <div class="p-item-apy">{{ $t('solana.starts_in') }}</div>
      </div>
      <div class="p-item-action" v-else-if="pool_type === 'active'">
        <v-btn
          rounded
          color="#1D1C39"
          dark
          class="active-btn"
          to="/solana/pool/reward"
        >
          {{ $t('solana.buttons.more') }}
        </v-btn>
      </div>
      <div class="p-item-action" v-else-if="pool_type === 'ended'">
        <v-btn
          rounded
          color="#F6E9E1"
          class="ended-btn"
          :class="{hide: detail}"
          @click="detail = !detail"
        >
          {{ detail ? $t('solana.buttons.hide') : $t('solana.buttons.show') }}
        </v-btn>
      </div>
    </div>
    <v-expand-transition>
      <div class="p-item-detail" v-show="detail">
        <div class="p-item-detail-row">
          <span>{{$t('solana.your_stake')}}</span>
          <span class="val">
            2.9003 SHT-ETH-V2
          </span>
        </div>
        <div class="p-item-detail-row">
          <span>{{ $t('solana.your_share') }}</span>
          <span class="val">
            0.004%
          </span>
        </div>
        <div class="p-item-detail-row">
          <span>{{$t('solana.rewards')}}</span>
          <span class="val">
            488.9 SHT
          </span>
        </div>

        <app-button class="no-shadow btn-solana w-100">
          {{$t('solana.claim_rewards_withdraw')}}
        </app-button>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import AppButton from '@/components/Button';

export default {
  name: 'PoolItem',
  components: { AppButton },
  data() {
    return {
      detail: false,
    };
  },
  props: {
    pool_type: String,
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors';

.p-item{
  border: 1px solid #1D1C3E;
  margin-bottom: 1.6rem;
  background: linear-gradient(180deg, #1F1E40 0%, #0E0D32 100%);
  border-radius: 3.2rem;

  &-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $s-text-color;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.1rem;
    letter-spacing: 0.01em;
    background: #0E0C29;
    padding: 1.2rem;
    border-radius: 3.2rem;
  }
  &-detail{
    padding: 0 2.4rem;
    &-row{
      font-size: 1.4rem;
      line-height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      letter-spacing: 0.01em;
      color: $s-text-color2;
      margin-top: 0.6rem;
      .val{
        color: $s-text-color;
      }
    }
    button{
      margin-top: 1rem;
      margin-bottom: 2.4rem;
    }
  }
  &-starts{
    min-width: 9.1rem;
  }
  &-action{
    button, a{
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      height: 4rem !important;
      border-radius: 4rem;
      color: #636890;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.2rem;
      text-transform: none;
    }
    .active-btn{
      color: #636890;
      min-width: 9.6rem;
    }
    .ended-btn{
      color: #161A86;
      min-width: 9.6rem;
      &.hide{
        border: 0.5px solid #29284E !important;
        box-sizing: border-box;
        background: #0E0C29 !important;
        color: $s-text-color;
      }
    }
  }
  &-name{
    display: flex;
    align-items: center;
  }
  &-subname{
    color: $s-green;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &-image{
    margin-right: 2.4rem;
    img{
      height: 2.8rem;
    }
  }
  &-apy{
    font-size: 1rem;
    line-height: 1.5rem;
    color: #636890;
  }
}
</style>
