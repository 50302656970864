<template>
  <div class="pool-card">
    <div class="pool-card-title">
      <img :src="require(`@/assets/img/solana/cards/2.png`)">
      SHT
    </div>
    <div class="pool-card-actions d-flex justify-center">
      <v-btn
        outlined
        color="#8982E2"
        rounded
      >
        {{ $t('solana.uniswap') }}
      </v-btn>

      <v-btn
        class="white--text"
        color="#3835FC"
        rounded
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        {{ $t('solana.add_liqudity') }}

      </v-btn>
    </div>

    <div class="pool-card-items">
      <div class="pool-card-item">
        <span>{{ $t('solana.total_liquidity') }}</span>
        <span class="val">$ 157,003,643.95</span>
      </div>
      <div class="pool-card-item">
        <span>{{ $t('solana.liquidity_staked') }}</span>
        <span class="val">$ 157,003,643.95</span>
      </div>
      <div class="pool-card-item">
        <span>{{ $t('solana.volume') }}</span>
        <span class="val">$ 157,643.95</span>
      </div>
    </div>

    <div class="pool-card-position">
      <div class="pool-card-position-title">{{ $t('solana.my_position') }}</div>
      <div class="pool-card-item">
        <span>{{ $t('solana.your_pool_tokens') }}:</span>
        <span class="val">
          0.02313
          <img src="@/assets/img/solana/eth-icon.svg" alt="eth">
        </span>
      </div>
      <div class="pool-card-item">
        <span>{{ $t('solana.pooled_dxd') }}:</span>
        <span class="val">
          0.048914
          <img src="@/assets/img/solana/eth-icon.svg" alt="eth">
        </span>
      </div>
      <div class="pool-card-item">
        <span>{{ $t('solana.pooled_weth') }}:</span>
        <span class="val">
          0.048914
          <img src="@/assets/img/solana/eth-icon.svg" alt="eth">
        </span>
      </div>
      <div class="pool-card-item">
        <span>{{ $t('solana.your_pool_share') }}:</span>
        <span class="val">0.00%</span>
      </div>
      <div class="pool-card-item">
        <span>{{ $t('solana.stake_utilized') }}</span>
        <span class="val">100% / 100%</span>
      </div>
    </div>

    <div class="pool-card-footer">
      <app-button
        class="no-shadow w-100 btn-solana capitalize"
        @click="stake_tokens_modal = !stake_tokens_modal"
      > {{ $t('solana.stake_tokens') }} </app-button>

    </div>
    <stake-tokens-modal v-model="stake_tokens_modal"/>
  </div>
</template>

<script>
import AppButton from '@/components/Button';
import StakeTokensModal from './StakeTokensModal';

export default {
  name: 'PoolCard',
  components: { StakeTokensModal, AppButton },

  data() {
    return {
      stake_tokens_modal: false,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/style/functions';
@import '@/assets/style/_colors';

.pool-card{
  background: #0E0C29;
  border-radius: 32px;
  box-sizing: border-box;
  border: 1px solid #1F1E40;
  padding: 3.2rem 2.4rem 2.4rem;
  margin-top: 2.2rem;
  &-title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.3rem;
    letter-spacing: 0.01em;
    color: $s-text-color;
    margin-bottom: 1.6rem;
    img{
      height: 2.8rem;
      margin-right: 1.2rem;

    }
  }
  &-item{
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: $s-text-color2;
    justify-content: space-between;
    .val{
      color: $s-text-color;
      display: flex;
      align-items: center;
      img{
        margin-left: 0.6rem;
        height: 1.6rem;
      }
    }
    &:not(:last-child){
      margin-bottom: 1.2rem;
    }
  }
  &-footer{
    margin-top: 4rem;
  }
  &-actions{
    margin-bottom: 4.9rem;
    button{
      height: 2.6rem !important;
      padding-left: 1.6rem !important;
      padding-right: 1.6rem !important;
      border-radius: 2.6rem;
    }
    button, button .v-btn__content{
      font-size: 1.2rem;
      text-transform: capitalize;
      font-weight: normal !important;
      &:not(:first-child){
        margin-left: 1.6rem;
      }
      i{
        font-size: 1.8rem;
        margin-right: 0.8rem;
      }
    }
  }
  &-position{
    background: #06051A;
    border-radius: 24px;
    padding: 1.6rem;
    margin-top: 1rem;
    &-title{
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.7rem;
      color: $s-text-color;
      margin-bottom: 1.6rem;
    }
    .pool-card-item{
      font-size: 1.4rem;
      line-height: 2.8rem;
      &:not(:last-child){
        margin-bottom: 0.4rem;
      }
    }
  }
}
</style>
