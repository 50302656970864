<template>
  <div class="pool-items">
    <div class="pool-items-title">
      {{ $t('solana.rewards_for_staking') }}
    </div>

    <div class="pool-items-tab">
      <v-tabs v-model="tab" class="solana-tabs">
        <v-tab v-for="item of items" :key="item"> {{ $t(`solana.status.${item}`) }} </v-tab>
      </v-tabs>

      <v-tabs-items class="solana-tabs-items" v-model="tab">
        <v-tab-item v-for="(item, k) of items" :key="k">
          <pool-item v-for="i in 4" :key="i" :pool_type="item"/>
        </v-tab-item>
      </v-tabs-items>
    </div>

  </div>
</template>

<script>
import PoolItem from './PoolItem';

export default {
  name: 'PoolItems',
  components: { PoolItem },
  data() {
    return {
      tab: 'Active',
      items: ['active', 'upcoming', 'ended'],
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/style/_colors';

.pool-items{
  &-title{
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $s-text-color;
    margin-bottom: 2rem;
  }
}
</style>
